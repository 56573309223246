import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { stringify } from 'qs';
import omit from 'lodash/omit';

import { httpClient } from '@/core/services/http-client';
import { getEnv, CommonError } from '@/common/utils';
import { type PinDetailsResponse, PinDetailsVM, PinsElementVM, type PinsListResponse, PinsListVM, type PinsParams } from '@/models/pins';

const config = getEnv();

const PIN_ENDPOINT = `${config.REACT_APP_API_URL}/outdoor-activity-api/admin/v1/pins`;

export const getPinList = (payload: PinsParams) => {
  const params = {
    name: payload.name,
    dateFrom: payload.dateFrom,
    dateTo: payload.dateTo,
    categories: payload.categories,
    region: payload.region,
  };

  return httpClient()
    .authorized.post<PinsListResponse>(`${PIN_ENDPOINT}/queries`, params, {
      params: {
        ...omit(payload, ['name', 'dateFrom', 'dateTo', 'categories', 'region']),
      },
      paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new PinsListVM({ data, VM: PinsElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const deletePin = (id: string) => {
  return httpClient()
    .authorized.delete(`${PIN_ENDPOINT}/${id}`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const getPinDetailsData = (id: string) =>
  httpClient()
    .authorized.get<PinDetailsResponse>(`${PIN_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new PinDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );
