import { combineReducers } from 'redux';

import { type FeatureStateType } from '@/store/helpers';
import { pinsListReducer } from './list';
import { pinDetailsReducer } from './details';

export type pinsReducer = FeatureStateType<typeof pinsReducer>;

export const pinsReducer = {
  pins: combineReducers({
    ...pinsListReducer,
    ...pinDetailsReducer,
  }),
};
