import { ActionType, Action, createAction } from 'typesafe-actions';

import { createFetchActions } from '@/common/utils/store';
import { type PinDetailsVM, type PinsListVM, type PinsParams } from '@/models/pins';

export const pinsActions = {
  list: createFetchActions('FETCH_PINS_LIST_REQUEST', 'FETCH_PINS_LIST_SUCCESS', 'FETCH_PINS_LIST_FAILURE')<PinsParams, PinsListVM>(),
  details: createFetchActions('FETCH_PIN_DETAILS_REQUEST', 'FETCH_PIN_DETAILS_SUCCESS', 'FETCH_PIN_DETAILS_FAILURE')<string, PinDetailsVM>(),
  clearPinDetails: createAction('CLEAR_PIN_DETAILS')(),
};

export type pinsActions = Action | ActionType<typeof pinsActions>;
