import { createSelector } from 'reselect';

import { type PinsListVM } from '@/models/pins';
import { getDataStatus } from '@/common/utils';
import { type pinsReducer } from './reducers';

const getPinsList = (state: pinsReducer) => state.pins.list;

type PinsList = pinsReducer['pins']['list'];
type MakeGetPinsListData = PinsListVM['list'];
type MakeGetPinsListPaginationData = PinsListVM['listPaginationData'] | undefined;
type MakeGetPinsListFilters = PinsList['params'];

export const makeGetPinsListData = () => createSelector<pinsReducer, PinsList, MakeGetPinsListData>(getPinsList, ({ data }) => data?.list || []);

export const makeGetPinsListStatus = () => createSelector<pinsReducer, PinsList, ReturnType<typeof getDataStatus>>(getPinsList, getDataStatus);

export const makeGetPinsListPaginationData = () =>
  createSelector<pinsReducer, PinsList, MakeGetPinsListPaginationData>(getPinsList, ({ data }) => data?.listPaginationData);

export const makeGetPinsListFilters = () => createSelector<pinsReducer, PinsList, MakeGetPinsListFilters>(getPinsList, ({ params }) => params);

const getPinDetails = (state: pinsReducer) => state.pins.details;

type PinDetails = pinsReducer['pins']['details'];
type MakeGetPinDetailsData = PinDetails['data'];

export const makeGetPinDetailsData = () => createSelector<pinsReducer, PinDetails, MakeGetPinDetailsData>(getPinDetails, ({ data }) => data);

export const makeGetPinDetailsStatus = () => createSelector<pinsReducer, PinDetails, ReturnType<typeof getDataStatus>>(getPinDetails, getDataStatus);
