import dayjs, { Dayjs } from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/utils';
import { type ApiPagination, type PageableListResponse, PageableListVM } from '@/common/utils/pageable';

export type PinsParams = ApiPagination<{
  region?: string;
  name?: string;
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
  categories?: string[];
}>;

export type PinsEntityResponse = {
  id: string;
  name: string;
  notes: string;
  category: string;
  reported: boolean;
  metadata: {
    updatedAt: string;
    createdAt: string;
    createdBy: string;
    creatorFirstName: string;
    creatorLastName: string;
    visibility: string;
    lastModifiedBy: string;
    sourceApplication: string;
  };
};

export type PinsListResponse = PageableListResponse<PinsEntityResponse>;
export type PinsDetailsResponse = PinsEntityResponse;

export interface PinsElementVM extends PinsEntityResponse {}

export class PinsElementVM {
  constructor({ metadata, ...props }: PinsEntityResponse) {
    Object.assign(this, {
      ...props,
      metadata: {
        ...metadata,
        createdAt: metadata.createdAt ? dayjs(metadata.createdAt).format(DEFAULT_DATE_FORMAT) : '',
      },
    });
  }
}

export class PinsListVM extends PageableListVM<PinsElementVM, PinsListResponse> {}

export type PinDetailsResponse = {
  id: string;
  name: string;
  notes: string;
  category: string;
  reported: boolean;
  metadata: {
    updatedAt: string;
    createdAt: string;
    createdBy: string;
    creatorFirstName: string;
    creatorLastName: string;
    creatorEmail: string;
    visibility: string;
    lastModifiedBy: string;
    sourceApplication: string;
  };
};

export interface PinDetailsVM extends PinDetailsResponse {}
export class PinDetailsVM {
  constructor({ metadata, ...props }: PinDetailsResponse) {
    Object.assign(this, {
      ...props,
      metadata: {
        ...metadata,
        createdAt: metadata.createdAt ? dayjs(metadata.createdAt).format(DEFAULT_DATE_FORMAT) : '',
      },
    });
  }
}
